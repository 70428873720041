export const msalConfig = {
  auth: {
    clientId: process.env.AADB2C_CLIENT_ID,
    authority: process.env.AADB2C_AUTHORITY,
    knownAuthorities: [process.env.AADB2C_KNOWNAUTHORITY],
    redirectUri: process.env.AADB2C_REDIRECT_URI,
    postLogoutRedirectUri: process.env.AADB2C_POSTLOGOUT_REDIRECT_URI,
  },
  cache: {
    storeAuthStateInCookie: true,
    cacheLocation: "localStorage",
  },
};

export const loginRequest = {
  scopes: [process.env.AADB2C_CLIENT_ID],
};

export const apiRequest = {
  url: process.env.API_URI,
  scopes: [process.env.API_SCOPE],
};
